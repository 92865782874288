import { useRef } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { twc } from 'react-twc';

import { Icon } from '@dandapani/web/ui/icons';

const TabTrigger = twc(
  Tabs.Trigger
)`border border-gray-700 px-4 py-2 text-left font-serif text-gray-700 transition-colors data-[state='active']:border-gray-900 data-[state='active']:text-gray-900 hover:text-red-500 hover:border-red-500`;

export function TestimonialsSlider({ children }: React.PropsWithChildren) {
  const ref = useRef<HTMLUListElement>(null);

  function slide(dir: 1 | -1) {
    if (ref.current) {
      const { width } = ref.current.getBoundingClientRect();
      ref.current.scroll({
        left: ref.current.scrollLeft + width * dir,
        behavior: 'smooth',
      });
    }
  }

  return (
    <section className="bg-gray-500 py-16 lg:pb-28 lg:pt-32">
      <div className="layout grid auto-rows-auto gap-8 lg:grid-cols-2 lg:grid-rows-1 lg:gap-24">
        <div className="col-start-1 mt-32">
          <h3 className="max-w-64 font-serif text-2.5xl sm:max-w-none sm:text-4xl">What others have to say</h3>
        </div>
        <div className="lg:col-start-2">
          <div className="auto-rows-auto grid-cols-3 gap-4 md:grid">
            <div className="ml-16 sm:ml-0 sm:max-w-64 md:max-w-none">
              <div className="flex items-center justify-between">
                <button
                  className="h-11 w-11 text-gray-900 transition-colors hover:text-red-500"
                  onClick={() => slide(-1)}
                >
                  <span className="sr-only">Previous testimonial</span>
                  <Icon icon="chevron" className="h-8 w-8" dir="left" />
                </button>
                <Icon icon="flower" className="h-11 w-11 text-red-500" />
                <button
                  className="h-11 w-11 text-gray-900 transition-colors hover:text-red-500"
                  onClick={() => slide(1)}
                >
                  <span className="sr-only">Next testimonial</span>
                  <Icon icon="chevron" className="h-8 w-8" />
                </button>
              </div>
            </div>
            <ul
              className="hide-scrollbar col-span-3 col-start-2 -mx-4 grid w-screen snap-x snap-mandatory scroll-pl-16 scroll-pr-4 auto-cols-[100%] grid-flow-col gap-16 overflow-x-scroll pl-16 pr-4 pt-8 sm:-mx-8 sm:scroll-px-8 sm:px-8 md:mx-0 md:w-full md:scroll-px-0 md:px-0 xl:pt-0"
              ref={ref}
            >
              {children}
            </ul>
            <Tabs.Root defaultValue="before" className="col-span-3 col-start-2 mt-10 space-y-4 md:mt-6 lg:col-start-1">
              <Tabs.List dir="horizontal" className="grid grid-cols-2 gap-5">
                <TabTrigger value="before">Before meeting Dandapani</TabTrigger>
                <TabTrigger value="after">After meeting Dandapani</TabTrigger>
              </Tabs.List>
              <Tabs.Content value="before">
                <p className="min-h-20 text-sm">
                  My life was a flurry of distractions and responsibilities before I joined Dandapani's course. The
                  constant chaos left me feeling lost, struggling to make progress amidst the noise.
                </p>
              </Tabs.Content>
              <Tabs.Content value="after">
                <p className="min-h-20 text-sm">
                  Since completing Dandapani's course, I've gained clarity and control. I've learned to direct my focus,
                  leading to increased productivity.
                </p>
              </Tabs.Content>
            </Tabs.Root>
          </div>
        </div>
      </div>
    </section>
  );
}
